<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_ErrorLogs',])"></div>
      <CardLeftBlock
          :name="$t('menu_ErrorLogs.localization_value.value')"
          :value="'#' + ErrorLog.data.id"
          :backgroundImage="'customs-info'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.errorLogs" class="order-create__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['menu_ErrorLogs',])"></div>
              {{$t('menu_ErrorLogs.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row">
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_route'])"></div>
                  {{ $t('errorLogs_route.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.data.baseURI }}
                    <a class="site-link" :href="ErrorLog.data.baseURI" target="_blank">click</a>
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_vueComponent'])"></div>
                  {{ $t('errorLogs_vueComponent.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.data.vueComponent }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_traceLine'])"></div>
                  {{ $t('errorLogs_traceLine.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col" style="line-height: 25px; font-size: 15px;">
                    {{ ErrorLog.data.error }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_routeHistory'])"></div>
                  {{ $t('errorLogs_routeHistory.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    <span v-for="(item, index) in ErrorLog.data.routeHistory" :key="index">
                      {{item}} <span v-if="ErrorLog.data.routeHistory.length - 1 !== index">=></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_user'])"></div>
                  {{ $t('errorLogs_user.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.data.user }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_typeError'])"></div>
                  {{ $t('errorLogs_typeError.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.data.typeError }}
                  </div>
                </div>
              </div>
              <div class="custom-col">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['errorLogs_userAgent'])"></div>
                  {{ $t('errorLogs_userAgent.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    {{ ErrorLog.data.userAgent }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  import {ErrorLog} from "@/components/globalModels/ErrorLog";

  export default {
    name: "ErrorLogsShow",

    components:{
      LinkBack,
      CardRightBlock,
      CardLeftBlock
    },

    data() {
      return {
        ErrorLog: new ErrorLog(),
      }
    },

    computed: {

    },

    mounted() {
      this.initErrorLogsShow()
    },

    methods: {
      initErrorLogsShow() {
          this.ErrorLog.setId(this.$route.params.id)

          this.$store.dispatch('getErrorLogs', this.ErrorLog.getId()).then((response) => {
            let item = this.getRespData(response)
            this.ErrorLog.setItem(
                {
                  baseURI: null,
                  vueComponent: null,
                  user: null,
                  userAgent: null,
                  error: null,
                  typeError :null,
                  routeHistory: null,
                },
                item
            )
          })//.catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
